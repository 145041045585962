"use client";
import { FC, memo, useCallback, useEffect, useState } from "react";

import { sendGTMEvent } from "@next/third-parties/google";
import { useLocale, useTranslations } from "next-intl";

import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { API_ROUTES } from "config/routes";

import { makeSearchFieldText, makeURLText } from "utils/searchUtils";
import backend from "utils/backend";

import { colorText600, colorText800 } from "theme/colors";
import { IcnExpandMode } from "theme/icons";

interface MainSearcherWithAutocompleteProps {
  q?: string;
  path?: string;
}

const MainSearcherWithAutocomplete: FC<MainSearcherWithAutocompleteProps> = ({
  q,
  path,
}) => {
  const t = useTranslations("common");
  const locale = useLocale();

  const [mainSearcherOutput, setMainSearcherOutput] = useState([]);
  const [text, setText] = useState("");
  const [submit, setSubmit] = useState(false);
  const [onFocus, setOnFocus] = useState(false);

  const handleSubmit = useCallback(() => {
    if (submit && onFocus && text !== "") {
      sendGTMEvent({ event: "search" });

      document.location.href = "/badania?q=" + makeURLText(text);
    } else {
      setSubmit(false);
    }
  }, [submit, onFocus, text]);

  useEffect(() => {
    setText(makeSearchFieldText(q, path));
  }, [q, path]);

  const makeQuery = useCallback(
    (q) => {
      backend
        .get(`${API_ROUTES.SEARCHER_AUTOCOMPLETE}?q=${q}&l=${locale}`)
        .then((res) => {
          const data = res.data;
          let values = [];
          if (data.spellcheck) {
            if (
              data.spellcheck.suggestions &&
              data.spellcheck.suggestions.length > 1
            ) {
              const suggestions = data.spellcheck.suggestions[1];
              if (suggestions.suggestion) {
                values = suggestions.suggestion.map((s) => {
                  return {
                    label: s,
                    title: s.replace(/<(?:.|\n)*?>/gm, ""),
                  };
                });
              }
            }
          }
          setMainSearcherOutput(values);
        });
    },
    [locale],
  );

  useEffect(() => {
    if (onFocus) {
      makeQuery("");
    }
  }, [onFocus, makeQuery]);

  useEffect(() => {
    handleSubmit();
  }, [submit, handleSubmit]);

  const handleChange = (e) => {
    setText(e.target.value);
    makeQuery(e.target.value);
  };

  const handleAutocompleteChange = (e, v) => {
    setText(v);
    setSubmit(true);
  };
  return (
    <Autocomplete
      fullWidth
      freeSolo
      sx={{
        zIndex: "0",
        width: "100%",
        margin: "0",
        padding: "0",
        border: "none",
        "& .MuiOutlinedInput-root": {
          padding: "0",
          backgroundColor: "white",
          color: colorText800,
        },
      }}
      disableClearable
      value={text}
      onChange={handleAutocompleteChange}
      options={mainSearcherOutput.map(
        (option: { title: string }) => option.title,
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="none"
          variant="outlined"
          onChange={handleChange}
          onFocus={() => setOnFocus(true)}
          placeholder={t("main-search")}
          value={text}
          onKeyDown={(e) => {
            if (e.key === "Enter" && text) {
              setSubmit(true);
            }
          }}
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: (
              <InputAdornment position={"start"}>
                <SearchIcon
                  sx={{ color: colorText600, margin: "10px 5px 10px 10px" }}
                  onClick={() => setSubmit(true)}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position={"end"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSubmit(true);
                  }}
                  sx={{
                    borderRadius: "0 4px 4px 0",
                    height: "47px",
                    px: 5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t("main-search-label2")}
                    <IcnExpandMode
                      style={{
                        width: "16px",
                        height: "16px",
                        transform: "rotateZ(270deg)",
                      }}
                    />
                  </Box>
                </Button>
              </InputAdornment>
            ),
            sx: {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-input::placeholder": {
                color: colorText600,
                opacity: 1,
                textIndent: "4px",
              },
              "& .MuiInputBase-input": {
                height: "32px",
                fontSize: "17px",
                caretColor: colorText800,
              },
            },
          }}
        />
      )}
    />
  );
};

export default memo(MainSearcherWithAutocomplete);
