export const makeURLText = (text: string | string[]): string | string[] => {
  if (typeof text === "string") {
    text = text.trim();
    text = text.replaceAll("/", "");
    text = text.replaceAll("\\", "");

    let result = text.toLowerCase();
    result = result.replaceAll(" ", "-");
    sessionStorage.setItem(`q_${result}`, text);
    return result;
  }
  return text;
};

export const makeSearchFieldText = (
  q?: string | string[],
  path?: string,
): string => {
  if (sessionStorage && q && typeof q === "string" && path === "/badania") {
    const textFromStorage = sessionStorage.getItem(`q_${q}`);
    return textFromStorage ? textFromStorage : q.replaceAll("-", " ");
  }

  return "";
};

export const makeSearchArticleFieldText = (q?: string | string[]): string => {
  if (sessionStorage && q && typeof q === "string") {
    const textFromStorage = sessionStorage.getItem(`q_${q}`);
    return textFromStorage ? textFromStorage : q.replaceAll("-", " ");
  }

  return "";
};

export const makeSearchTitleText = (q?: string | string[]): string => {
  if (q && typeof q === "string") {
    return q.replaceAll("-", " ");
  }
  return "";
};
